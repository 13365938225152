import React from "react"
import styled from "styled-components"
//import ReactGA from "react-ga"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import Hero from "../components/CustomerSegmentHero"
import CustomerSegmentValueProps from "../components/CustomerSegmentValueProps"
import CustomerSegmentCarousel from "../components/CustomerSegmentCarousel"
import CustomerSegmentCenterTestingBlurb from "../components/CustomerSegmentCenterTestingBlurb"
import CustomerSegmentQuestion1 from "../components/CustomerSegmentQuestion1"
import CustomerSegmentApp from "../components/CustomerSegmentApp"


import Case1 from "../images/case_janet.jpg"
import Case2 from "../images/case_harris.jpg"

import PC1 from "../images/cx-slider1.jpeg"
import PC2 from "../images/cx-slider2.jpeg"
import PC3 from "../images/cx-slider3.jpeg"
import PC4 from "../images/cx-slider4.jpeg"
import HeroBackground from "../images/cx-hero-bg.jpg"
import HcmDescription from "../images/cx-description.jpg";
import { CX_FEATURES } from "../lib/constants";

import Screen1 from "../images/cx-mobile-screen1.jpeg";
import Screen2 from "../images/cx-mobile-screen2.jpeg";

import { sizes } from "../lib/layout"


const list = [
  "Oracle Marketing",
  "Oracle Sales",
  "Configure, Price, Quotes (CPQ)",
  "Oracle Service",
  "(CPQ)",
  "Oracle Service"
]

const arrayOfImages = [Case1, Case2]

const arrayOfQuotes = [
  {
    
    quote: "Elevate your business with Oracle CX Cloud.",
    background:
      "Great CX software requires exceptional implementation skill to ensure that your company gains a competitive advantage in the world of customer experience.",
  },
  {
    quote: "Drive better CX from your existing Siebel system.",
    background:
      " Combining UX design and Open UI we’ll transform your Siebel system, maximising efficiency, and saving time for your users and customers.",
  },
]

const CX = props => {
  const {
    location: { pathname },
  } = props

  return (
    <Layout pathName={pathname}>
      <SEO title="CX" keywords={[`mivors`, `cx`, `oracle`,`customer experience`,`oracle`,`loyalty`,`realestate`,`automotive`,`service`]} />
      <Hero
        bg={HeroBackground}
        title="Experience the future of customer experience"
        description="We help organizations build the future of customer experience with the Oracle CX Cloud Suite in realestate, automotive and service industries . From sales and marketing to omnichannel customer service and e-commerce, we connect data with intelligence to deliver stand-out experiences."
      />
        <CustomerSegmentValueProps
          bgColor="#FDBE11"
          features={CX_FEATURES}
        />
        <CustomerSegmentCarousel
          img={HcmDescription}
          title={`<strong>"Elevate your business with Oracle CX Cloud."</strong>`}
          description="Great CX software requires exceptional implementation skill to ensure that your company gains a competitive advantage in the world of customer experience."
        />
        <CustomerSegmentQuestion1
          title="CUSTOMER EXPERIENCE IS A PERCEPTION"
          descriptions={[
            "What is customer experience, and why is it so important? From a business perspective, CX is tangible, practical, and measurable. Customer experience is Customers' perceptions of their interactions with a brand. — Forbes Magazine quotes Customer Experience is your New Brand. There are three dimensions to these perceptions — the measurable components of an experience that drives Customer Loyalty."
          ]}
        />
        <CustomerSegmentCenterTestingBlurb
          bgColor="#E8AA00"
          title="WHAT’S INVOLVED IN BEING THE HEAD OF CX"
          description="mivors is a leader in implementing Modern CX, helping businesses leverage the latest advancements in AI, predictive analytics and robotics process automation to deliver an unforgettable customer experience to stand out from your competition. We help our clients realize their full potential of their Oracle CX investment by streamlining agent operations, focusing on process automation, and providing self-service and assisted knowledge services. Our white-gloved approach will help you implement the right platform and strategy to support your customer experience goals with accelerated ROI including:"
          images={[PC1, PC2, PC3, PC4]}
          content={
            <>
              <p>
                  <ul>
                    {
                      list.map(li => (
                        <li key={li}>{li}</li>
                      ))
                    }
                  </ul>
                  mivors, along with the Icloud-ready Idengager gives you everything you need to build end to end digital engagement joureny for your customer.
              </p>
            </>
          }
        />
        <CustomerSegmentApp
          title="We Launch your mobile digital engagement integrated with your oracle cx cloud in weeks"
          description="We deliver Oracle CX solution integrated with Icloud-ready customer engagement solution which enable us to deliver ready to launch customer expereince solution for your industry."
          direction="ltr"
          images={[Screen1, Screen2]}
        />
    </Layout>
  )
}

export default CX
